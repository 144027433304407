import * as React from "react";
import * as styles from "./Exchanges.module.scss";
import Container from "../../components/container/container";
import KucoinIcon from "../../svg/kucoin.svg";
import UniswapIcon from "../../svg/uniswap.svg";
import InchIcon from "../../svg/inch.svg";
import GateIcon from "../../svg/gateio.svg";

const exchanges = [
  {
    icon: <KucoinIcon />,
    url: "https://www.kucoin.com/trade/LSS-USDT",
  },
  {
    icon: <GateIcon />,
    url: "https://www.gate.io/trade/LSS_USDT",
  },
  {
    icon: <UniswapIcon />,
    url: "https://v2.info.uniswap.org/token/0x3b9be07d622accaed78f479bc0edabfd6397e320",
  },
  {
    icon: <InchIcon />,
    url: "https://app.1inch.io/#/1/swap/LSS/USDT",
  },
];

const Exchanges = ({ items }) => {
  return (
    <section className={styles.exchanges}>
      <Container>
        <h2>
          Purchase <span>Lossless ($LSS)</span> Tokens From:
        </h2>
        <div className={styles.exchangesWrap}>
          {exchanges.map((exchange, index) => (
            <div className={styles.exchangesItemWrap} key={index}>
              <a
                className={styles.exchangesItem}
                href={exchange.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {exchange.icon}
              </a>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Exchanges;
