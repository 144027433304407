import * as React from "react";
import Layout from "../components/layout/layout";
import LottieAnimation from "../components/lootie/Lottie";
import Hero from "../sections/hero/hero";
// import Image from "../images/token-hero.jpg";
import SectionWithIcons from "../sections//sectionWithIcons/sectionWithIcons";
// import BlocksWithIcons from "../sections/blocksWithIcons/BlocksWithIcons";
import Exchanges from "../sections/exchanges/Exchanges";
import Token from "../sections/token/Token";
// import CustomLink from "../components/customLink/CustomLink";
import HeroVideo from "../videos/token.mp4";
import FadeIn from "../components/fade/fade";

import ReportGenerationJson from "../json/Report_Generation.json";
import CommunityStakingJson from "../json/Community_Staking.json";
import RewardDistributionJson from "../json/Reward_Distribution.json";

const sectionWithIconsData = {
  sectionColor: "white",
  titleMaxWidth: "891px",
  title:
    "<span>$LSS</span> is an <span>ERC-20 Standard</span> token, designed to facilitate smooth functioning of the Lossless protocol and reward active community members",
  text: "<span>$LSS</span> Token utility and use cases include:",
  items: [
    {
      icon: <LottieAnimation animationJson={ReportGenerationJson} />,
      title: "Report Generation",
      text: "Finders stake $LSS to participate in exploit spotting, generating reports on transactions they suspect originate from suspicious activity. Addresses of allegedly malicious actors are then frozen for 24-48 hours.",
    },
    {
      icon: <LottieAnimation animationJson={CommunityStakingJson} />,
      title: "Community Staking",
      text: "Community Stakers may stake $LSS on top of reports they deem credible. By expressing their vote of confidence, Community Stakers increase the report’s visibility.",
    },
    {
      icon: <LottieAnimation animationJson={RewardDistributionJson} />,
      title: "Reward Distribution",
      text: "Lossless Decision-Making Body resolves open reports. Recovered funds are subject to a 7% fee, used to purchase $LSS and distribute rewards to Finders and Community Stakers.",
    },
  ],
  buttons: [
    {
      type: "",
    },
  ],
};

const TokenPage = () => {
  return (
    <Layout pageTitle="Token - Lossless">
      <Hero
        title="Lossless ($LSS) Token"
        text="$LSS forms an integral part of the Lossless cybersecurity protocol, ensuring the integrity of our stake-based reporting system, stable project governance, and exclusive access to Lossless promotions."
        // backgroundUrl={Image}
        titleMaxWidth="78rem"
        textMaxWidth="77.1rem"
        video={HeroVideo}
      />
      <FadeIn>
        <SectionWithIcons
          sectionColor={sectionWithIconsData.sectionColor}
          titleMaxWidth={sectionWithIconsData.titleMaxWidth}
          title={sectionWithIconsData.title}
          items={sectionWithIconsData.items}
          text={sectionWithIconsData.text}
        ></SectionWithIcons>
      </FadeIn>

      <FadeIn>
        <Token />
      </FadeIn>
      <FadeIn>
        <Exchanges />
      </FadeIn>
    </Layout>
  );
};

export default TokenPage;
