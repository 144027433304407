import React, { useState, useEffect, useRef } from "react";
import * as styles from "./Token.module.scss";
import Container from "../../components/container/container";
import LinkIcon from "../../svg/link.svg";
import CopyIcon from "../../svg/copy.svg";
import ArrowDownIcon from "../../svg/arrow-down-full.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const tokenData = {
  items: [
    {
      title: "Ticker:",
      text: "LSS",
    },
    {
      title: "Token type:",
      text: "ERC-20",
    },
    {
      title: "Total Supply:",
      text: "100,000,000 LSS",
    },
  ],
};

const Token = ({}) => {
  const [marketChartLabels, setMarketChartLabels] = useState([]);
  const [marketChartValues, setMarketChartValues] = useState([]);

  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenPriceChange, setTokenPriceChange] = useState(0);
  const [circulatingSupply, setCirculatingSupply] = useState(0);

  const chartRef = useRef(null);
  useEffect(() => {
    fetch(
      `https://api.coingecko.com/api/v3/coins/lossless/market_chart?vs_currency=usd&days=max`
    )
      .then((response) => response.json())
      .then((resultData) => {
        const { labels, values } = {
          labels: resultData.prices.map((a) => {
            let date = new Date(a[0]);
            let options = {
              weekday: "short",
              month: "short",
              day: "numeric",
              year: "numeric",
            };
            return date.toLocaleDateString("en-US", options);
          }),
          values: resultData.prices.map((a) => a[1]),
        };

        setMarketChartLabels(labels);
        setMarketChartValues(values);
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://api.coingecko.com/api/v3/coins/lossless?tickers=false&community_data=false&developer_data=false&sparkline=false`
    )
      .then((response) => response.json())
      .then((resultData) => {
        setTokenPrice(resultData.market_data.current_price.usd);
        setTokenPriceChange(
          resultData.market_data.price_change_percentage_24h.toFixed(2)
        );
        setCirculatingSupply(resultData.market_data.circulating_supply);
      });
  }, []);

  const chartOptions = {
    aspectRatio: 5.6916996047,
    backgroundColor: "red",
    elements: {
      point: {
        radius: 3,
      },
    },
    hover: {
      intersect: false,
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          tickMarkLength: 0,
        },
        scales: {
          beginAtZero: true,
        },
        ticks: {
          beginAtZero: true,
          display: false,
          tickLength: 0,
          tickWidth: 0,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          tickMarkLength: 0,
        },
        scales: {
          beginAtZero: true,
        },
        ticks: {
          beginAtZero: true,
          display: false,
          tickLength: 0,
          tickWidth: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        padding: 12,
        bodyColor: "#000",
        titleColor: "#666666",
        labelColor: false,
        displayColors: false,
        beforeLabel: false,
        intersect: false,
        titleMarginBottom: 4,
        bodySpacing: 0,
        titleFont: {
          size: 12,
          family: "Suisse",
          weight: 400,
        },
        bodyFont: {
          size: 16,
          family: "Suisse",
          weight: 400,
        },
        callbacks: {
          label: function (tooltipItem) {
            return `$${tooltipItem.formattedValue}`;
          },
        },
      },
    },
  };

  const chart = chartRef.current;

  const data = {
    labels: marketChartLabels,
    datasets: [
      {
        label: "$",
        borderColor: "#fff",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointColor: "#000",
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(9, 4, 29, 0.1)",
        hoverBackgroundColor: "#fff",
        hoverBorderColor: "#1d4d42",
        hoverBorderWidth: "1",
        lineTension: 0.4,
        fill: "start",
        data: marketChartValues,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            `${chart && chart.height ? chart.height : 0}`
          );
          gradient.addColorStop(0, "rgba(76, 127, 116, 1)");
          gradient.addColorStop(0.95, "rgba(44, 92, 81, 1)");
          gradient.addColorStop(1, "rgba(44, 92, 81, 1)");

          return gradient;
        },
      },
    ],
  };

  return (
    <section className={styles.token}>
      <Container>
        <div className={styles.tokenTop}>
          <h2>The Lossless Token</h2>
          <div className={styles.tokenPriceWrap}>
            <span className={styles.tokenPriceText}>Price 24h</span>
            <div className={styles.tokenPriceContent}>
              <div className={styles.tokenPrice}>${tokenPrice}</div>
              <div
                className={`${styles.tokenPriceLabel} ${
                  tokenPriceChange < 0 && styles.tokenPriceLabelNegative
                }`}
              >
                <ArrowDownIcon />
                {Math.abs(tokenPriceChange)}%
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tokenContent}>
          {tokenData.items.map((item, index) => (
            <div className={styles.tokenContentItem} key={index}>
              <h3>{item.title}</h3>
              <div className={styles.tokenContentItemBottom}>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
          <div className={styles.tokenContentItem}>
            <h3>Circulating Supply:</h3>
            <div className={styles.tokenContentItemBottom}>
              <p>
                {circulatingSupply.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}{" "}
                LSS
              </p>
            </div>
          </div>
          <div className={styles.tokenContentItem}>
            <h3>Contract:</h3>
            <div className={styles.tokenContentItemBottom}>
              <p
                data-text-mobile={"0x3b9be07d622a...e320"}
                data-text-desktop={"0x3b9be07d622accaed7...e320"}
                className={styles.tokenContentItemAddress}
              ></p>
              <button
                onClick={() =>
                  navigator.clipboard.writeText(
                    "0x3B9BE07d622aCcAEd78f479BC0EDabFd6397E320"
                  )
                }
              >
                <CopyIcon />
              </button>
              <a
                href="https://etherscan.io/token/0x3b9be07d622accaed78f479bc0edabfd6397e320"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.tokenChart}>
        <Line options={chartOptions} data={data} ref={chartRef} />
      </div>
    </section>
  );
};

export default Token;
