// extracted by mini-css-extract-plugin
export var token = "Token-module--token--W0-Ul";
export var tokenChart = "Token-module--token-chart--e4Xzi";
export var tokenContent = "Token-module--token-content--NVk17";
export var tokenContentItem = "Token-module--token-content-item--sDRsf";
export var tokenContentItemAddress = "Token-module--token-content-item-address--mHj8W";
export var tokenContentItemBottom = "Token-module--token-content-item-bottom--0dpJ8";
export var tokenPrice = "Token-module--tokenPrice--x4PGy";
export var tokenPriceContent = "Token-module--tokenPriceContent--7zbE0";
export var tokenPriceLabel = "Token-module--tokenPriceLabel--WpuA3";
export var tokenPriceLabelNegative = "Token-module--tokenPriceLabelNegative--nigJn";
export var tokenPriceText = "Token-module--tokenPriceText--0N2YA";
export var tokenPriceWrap = "Token-module--token-price-wrap--9j2W2";
export var tokenTop = "Token-module--token-top--rwBWv";